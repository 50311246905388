let utils = {

};

utils.Console = function(option){
    const title = option.title;
    const content = option.content;
    const backgroundColor = option.backgroundColor;
    const arg = [
        "%c ".concat(title, " %c ").concat(content, " "),
        "padding: 1px; border-radius: 3px 0 0 3px; color: #fff; background: ".concat(
        "#606060",
        ";"
        ),
        "padding: 1px; border-radius: 0 3px 3px 0; color: #fff; background: ".concat(
        backgroundColor,
        ";"
        )
    ];
    window.console && typeof window.console.log === "function" &&  window.console.log.call(null, ...arg);
}

utils.PrintEnvInfo = function(){
    utils.Console({
        title: "Build Date",
        // eslint-disable-next-line no-undef
        content: MEISHE_BUILD_DATE,
        backgroundColor: "#1475b2"
      });
      utils.Console({
        title: "Commit",
        // eslint-disable-next-line no-undef
        content: MEISHE_COMMITHASH,
        backgroundColor: "#00B294"
      });

      utils.Console({
        title: "Branch",
        // eslint-disable-next-line no-undef
        content: MEISHE_BRANCH,
        backgroundColor: "#FFB900"
      });
      
}

export default utils;