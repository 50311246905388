<template>
  <div >
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  /* data(){
    return{
      userId:'',
      token:'',
    }
  },
  mounted(){
      this.userId = this.getRequest().userId ? this.getRequest().userId : "";
      this.token = this.getRequest().token ? this.getRequest().token : "";
      this.$router.push(`/RedBag?userId=${this.userId}&token=${this.token}`)
  },
  methods:{
    //格式化URL
        getRequest: () => {
            var url = window.location.search; //获取url中"?"符后的字串
            var theRequest = new Object();
            if (url.indexOf("?") != -1) {
                var str = url.substr(1);
                var strs = str.split("&");
                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = decodeURI(
                        strs[i].split("=")[1]
                    );
                }
            }

            return theRequest;
        },
  } */
}
</script>

<style>
body{
  margin: 0;
}
</style>
