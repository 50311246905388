const RedBag = ()=> import("../pages/redbag.vue");
const Withdraw = ()=> import("../pages/withdraw.vue");
const Result = ()=> import("../pages/result.vue");
const Rule = ()=> import("../pages/rule.vue");

export default [
    {path:"/RedBag",component:RedBag,meta:{title:'红包收益'}},
    {
        path:"/RedBag",
        component:RedBag,
        children:[
            {
                path:'Withdraw',
                component:Withdraw,
                children:[
                    {
                        path:'Result',
                        component:Result,
                        meta:{title:'红包收益'}
                    },
                ],
            },
        ],
        meta:{}
    },
    {
        path:"/RedBag",
        component:RedBag,
        children:[
            {
                path:'Withdraw',
                component:Withdraw,
                meta:{title:'红包收益'}
            },
        ],
        meta:{}
    },
    {
        path:"/RedBag",
        component:RedBag,
        children:[
            {
                path:'Rule',
                component:Rule,
                meta:{title:'规则说明'}
            },
        ],
        meta:{}
    },
    {
        path:"*",
        redirect:"/RedBag",
    },
]