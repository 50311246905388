import axios from "axios";
import Qs from 'qs'
var instance = axios.create({});
instance.defaults.timeout = 10000;

instance.interceptors.response.use(
  // 请求成功
  res => (res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res)),
  // 请求失败
//   error => {
    // const { response } = error;
    /* if (response) {
      if (error.response.status) {
        switch (error.response.status) {
          case 404:
            message.error(
              `服务器返回404，接口：${error.response.config.url} 请联系管理员解决此问题`,
              10
            );
            break;
          default:
            message.error(
              `错误代码：${error.response.status},错误：${error.response.data.msg}，接口：${error.response.config.url}，请联系管理员解决此问题`,
              10
            );
        }
        return Promise.reject(error.response);
      }
    } else {
      // 处理断网的情况
      console.log(error);
      message.error(`无法连接到服务器，请检查网络`);
    } */
//   }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, api_name) {
  return request(url, "get", params, api_name);
}

/* export function del(url, params, api_name) {
  return request(url, "delete", params, api_name);
} */



/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, api_name) {
  return request(url, "post", params, api_name);
}

/* export function put(url, params, api_name) {
  return request(url, "put", params, api_name);
} */

export function request(url, method, params, api_name) {
  if (method === "post") {
    params = Qs.stringify(params);
  } else {
    params = {
      ...params,
    };
  }
  if (method == "get") {
    params = { params: params };
  }
  //判断下URL有没有t_branch
  // console.log("lolo",window.location)
  try {
    let current_url = new URL(window.location);
    let branch = current_url.searchParams.get("branch");
    if (branch && branch.length > 0) {
      url = addURLParam(url, "t_branch", branch);
    }
    // console.log("添加了参数",url)
  } catch (e) {
    console.error("解析网页URL错误", e);
  }
  return new Promise((resolve, reject) => {
    instance[method](url, params)
      .then(res => {
        if (res.code == 0) {
          resolve(res.data);
        } else if (res.code == 2) {
          showAuthCard(api_name, res.data);
        


          reject(res);
        } else if (res.error) {
          /* notification.open({
            // message: `[${api_name}] 发生错误`,
            description: JSON.stringify(res.error_stack),
            duration: null
          }); */
          reject(res);
        } else if (res.code == undefined) {
          resolve(res);
        } else {
        //   message.error(`${api_name}错误! 错误信息: ${res.msg}`, 10);
          reject(res);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}

/* function showAuthCard(api_name, data) {
//   const key = `auth_card`;
  if (!data.resource_key && window.authOpen) {
    return false;
  }
  window.authOpen = true;
 /*  notification.open({
    // message: `[${api_name}] 需要授权`,
    description: data.msg,
    icon: h => {
      return h("a-icon", {
        props: {
          type: "lock"
        },
        style: {
          color: "#EF5B25",
          "font-size": "32px",
          "margin-top": "20px"
        }
      });
    },
    duration: null,
    btn: h => {
      return h(
        "a-button",
        {
          props: {
            type: "primary"
            //size: 'small',
          },
          on: {
            click: () => {
              if (data.resource_key) {
                window.open(
                  `https://ee.byted.org/kani/apps/1045/resources/${data.resource_key}/`
                );
              } else {
                Modal.warning({
                  title: "提示",
                  content: "该权限无法直接申请，请联系管理员"
                });
              }
              notification.close(key);
              window.authOpen = false;
            }
          }
        },
        "申请权限"
      );
    },
    key,
    onClose: close
  }); */
// }

function addURLParam(uri, key, value) {
  if (!value) {
    return uri;
  }
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
}

// export default instance;
