import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import 'lib-flexible'
import utils from './utils'
import api from './http/api'  
import md5 from 'js-md5'
import title from 'vue-wechat-title'


Vue.use(title)
Vue.prototype.$api = api
Vue.prototype.$md5 = md5
Vue.config.productionTip = false
utils.PrintEnvInfo()
new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
