import { get, post,del,put } from './http'
const isProject = true
const basicUrl = isProject ? "https://api.open.meisheapp.com" : "http://testapi.open.meisheapp.com"

export const getRedBagInfo = (app_key,timestamp,sign,token,user_id) =>get(`${basicUrl}/RedPacket/getList?app_key=${app_key}&timestamp=${timestamp}&sign=${sign}&token=${token}&user_id=${user_id}`,{},"")
export const getUserInfo = (app_key,timestamp,sign,token,user_id) =>get(`${basicUrl}/RedPacket/queryAuthorization?app_key=${app_key}&timestamp=${timestamp}&sign=${sign}&token=${token}&user_id=${user_id}`,{},"")
//保存授权信息
export const saveAuthorization = (app_key,timestamp,sign,token,user_id,openid,nickname,headimgurl) =>post(`${basicUrl}/RedPacket/saveAuthorization?app_key=${app_key}&timestamp=${timestamp}&sign=${sign}&token=${token}&user_id=${user_id}&openid=${openid}&nickname=${nickname}&headimgurl=${headimgurl}`,{},"")
//查询授权信息
export const cheakAuthorization = (app_key,timestamp,sign,token,user_id) =>get(`${basicUrl}/RedPacket/queryAuthorization?app_key=${app_key}&timestamp=${timestamp}&sign=${sign}&token=${token}&user_id=${user_id}`,{},"")
//申请提现
export const goMoney = (app_key,timestamp,sign,token,user_id,open_id,amount,activity_id) =>post(`${basicUrl}/RedPacket/withdrawalApply`,{app_key,timestamp,sign,token,user_id,open_id,amount,activity_id},"")


export default {   
    getRedBagInfo,
    getUserInfo,
    saveAuthorization,
    cheakAuthorization,
    goMoney,
}